import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

//Import Images
import footerlogo from "../../assets/images/Supersoft-Logo.png";


class Footer extends Component {
  state = {
    
    socialIcons: [
      { icon: "remixicon-facebook-line", iconLink: "#" },
      { icon: "remixicon-twitter-line", iconLink: "#" },
      { icon: "remixicon-instagram-line", iconLink: "#" },
      { icon: "remixicon-skype-line", iconLink: "#" },
    ],
  };

  render() {
    return (
      <React.Fragment>
        {/* FOOTER START  */}
        <footer className="pt-3 pb-4 position-relative bg-light" id="footer">
          <Container className="container">
            <Row>
              <Col lg={12}>

                <div className="footer-about-content mt-2 d-flex justify-content-center align-items-center flex-column">
                  {/* <img
                    src={footerlogo}
                    alt="footerLogo"
                    // className="img-fluid"
                    style={{maxWidth: "320px"}}
                  /> */}
                
                  {/* <ul className="list-inline footer-icon mt-4">
                    {this.state.socialIcons.map((item, key) => (
                      <li className="list-inline-item h4 mr-4" key={key}>
                        <Link to={item.iconLink} className="text-purple">
                          <i className={item.icon} />
                        </Link>
                      </li>
                    ))}
                  </ul> */}
                </div>
              </Col>
          
            </Row>
            <FooterLinks />
          </Container>
        </footer>

        {/* FOOTER END  */}
      </React.Fragment>
    );
  }
}

export default Footer;
