import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import 'remixicon/fonts/remixicon.css'
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

{/* <script src="https://unpkg.com/react-spring@8.0.15/web.cjs.js" integrity="sha512-MH5I0vaMuEXznrfSF/eA9IuFMVgMIbs4Q3kP5KbdeZRuOLkNoOpdVop4nZhLK1r9+vVZJ66h570c2Oo0hFBeqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script> */}

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
