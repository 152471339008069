import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/SectionTitle";

class Pricing extends Component {
  pricingPlan1 = {
    pricings: [
      {
        id: 1,
        title: "Creative Idea",
        para: "We are an organization that is build and engineered to work with both creativity and with client's satisfaction.",
      },
    ],
  };

  pricingPlan2 = {
    pricings: [
      {
        id: 2,
        title: "Quality Production",
        para: "Our team is skillfull and dedicated in the work they are provided with, which eventually leads to the production of excellence.",
      },
    ],
  };

  pricingPlan3 = {
    pricings: [
      {
        id: 3,
        title: "Customized Solutions",
        para: "We render expensive customized IT Solutions at expensive price with ease of client and other stake Holders.",
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        {/* PRICING START */}
        <section className="section bg-light" id="pricing">
          <Container>
            <SectionTitle
              title=" Why choose us?"
              description="We provide best service in reasonable amount"
            />

            <Row>
              {this.pricingPlan1.pricings.map((pricingPlan1, key) => (
                <Col lg={4} key={pricingPlan1.currency}>
                  <div
                    className="pricing-box text-center bg-white p-5 mt-4 position-relative"
                    key={pricingPlan1.id}
                  >
                    <div className="mt-4 mb-5">
                      <i className="ri-lightbulb-flash-fill h3 text-purple pricing-icon p-4 rounded-circle" />
                    </div>
                    <h4>{pricingPlan1.title}</h4>
                    <p>{pricingPlan1.para}</p>
                  
                  </div>
                </Col>
              ))}

              {this.pricingPlan2.pricings.map((pricingPlan2, key) => (
                <Col lg={4} key={pricingPlan2.id}>
                  <div className="pricing-box text-center bg-white p-5 mt-4 position-relative">
                    <div className="mt-4 mb-5">
                      <i className="ri-vip-crown-fill h3 text-purple pricing-icon p-4 rounded-circle" />
                    </div>
                    <h4>{pricingPlan2.title}</h4>
                    <p>{pricingPlan2.para}</p>
                   
                  </div>
                </Col>
              ))}

              {this.pricingPlan3.pricings.map((pricingPlan3, key) => (
                <Col lg={4} key={pricingPlan3.id}>
                  <div className="pricing-box text-center bg-white p-5 mt-4 position-relative">
                    <div className="mt-4 mb-5">
                      <i className="ri-bubble-chart-line h3 text-purple pricing-icon p-4 rounded-circle" />
                    </div>
                    <h4>{pricingPlan3.title}</h4>
                    <p>{pricingPlan3.para}</p>
                  
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* PRICING END  */}
      </React.Fragment>
    );
  }
}

export default Pricing;
