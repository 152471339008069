import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/SectionTitle";
import ServiceBox from "./service-box";

import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined';
import {AbcOutlinedIcon} from '@mui/icons-material/AbcOutlined';


class Services extends Component {

  state = {
    services: [
      {
        title: "Customized e-Commerce Solution",
        icon: "pe-7s-cart",
        description:
          <div>
            We specialize in providing tailored e-commerce solutions for businesses of all sizes. Our team of experts will work closely with you to understand 
            your specific needs and create a customized platform that meets your requirements. We have experience with a wide range of e-commerce standard platforms,
            and we'll recommend the best platform for your business based on your unique needs.
          </div>,
        link: "#",
      },
      {
        title: "Web Development",
        icon: "pe-7s-server",
        description:
          <div>We offer comprehensive web development services to help businesses build a strong online presence
          , custom website designs that are visually appealing, user-friendly, and optimized for search engines. <br/>
          We develop custom web applications that can help businesses streamline their processes, improve efficiency, and boost productivity.
          We offer ongoing website maintenance and support to ensure that our clients' websites are always up-to-date.
          </div>,
        link: "#",
      },
      {
        title: "Digital Marketing",
        icon: "pe-7s-rocket",
        description:
          <div>
            At SuperSoft, we specialize in providing digital marketing services to help businesses reach their target audience, generate leads, 
            and increase sales.<br/>     
            
            Social media is a powerful tool for building brand awareness and engaging with your audience. Our social media marketing experts will develop a customized
             social media strategy that will help you reach your target audience, build brand awareness, and drive engagement. 
          </div>,
        link: "#",
      },
      {
        title: "Mobile App Development",
        icon: "pe-7s-phone",
        description:
          <div>
            we specialize in creating mobile apps that are functional, user-friendly, and visually stunning.
            <br/>            
            Hybrid app development involves building apps that can run on multiple platforms using a single codebase. Our 
            hybrid app developers use the latest technologies and frameworks to build high-quality hybrid apps that are fast, efficient, and cost-effective.
          </div>,
        link: "#",
      },
      {
        title: "Data Analytics",
        icon: "pe-7s-graph1",
        description:
          <div>
            At our company, we provide comprehensive and customized data analytics solutions to help businesses make data-driven decisions. Our team of experienced
            data analysts and scientists have the skills and expertise to handle complex data challenges and provide insights that can help you optimize your business processes, increase efficiency, and drive growth.
          </div>,
        link: "#",
      },
    
      {
        title: "Desktop Development",
        icon: "pe-7s-monitor",
        description:
          <div>
             Custom desktop applications that are tailored to meet your unique business needs.
             If you have legacy desktop applications that are no longer meeting your business needs, our developers can help you modernize them.
             <br/>
             From desktop application integration with othes systems apps to support, our team ensure  that you get quality service and on time.
          </div>,
        link: "#",
      },
   
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="service" className="section position-relative">
          <Container>
            <SectionTitle
              title="Our Services"
              description="We provide best service in reasonable amount"
            />
            <Row>
              <ServiceBox services={this.state.services} />
            </Row>
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default Services;
