import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Common/SectionTitle";

//Import Images
import img1 from "../../assets/images/features-img-1.webp";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        {/* FEATURES START  */}
        <section className="section bg-light position-relative" id="features">
          <Container>
            <SectionTitle
              title="About Us"
              description="Serving Humanity via IT"
            />

            <Row className="align-items-center">
              <Col lg="6">
                <div className="features-img mt-4">
                  <img
                    src={img1}
                    alt="Fetures"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            <Col lg="6">
            <div className="col-lg-12 offset-lg-1">
                <div className="pr-lg-5 mt-4">
                  <h4 className="line-height-1_6 text-dark mb-4">
                    <span className="font-weight-600">Business Solutions.</span>
                  </h4>
                  <p className="text-muted mb-4 f-15">
                  The focus of the company is to render expensive customized IT Solutions at an inexpensive price with ease of client and other stakeholders. Mean turning business complexity into ease which ensures every bit of business need.
                  </p>

                  
                </div>
              </div>
              
            </Col>
          
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
