import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { InlineWidget, PopupWidget, PopupButton } from "react-calendly";

class Appointment extends Component {
    render() {
        return(
            <React.Fragment>
                <section id="appointment" className="bg-light position-relative text-center">
                    <Container className="container">
                        <Row>
                            <Col lg={12}>
                                <PopupButton
                                    className="aptBtn"
                                    url="https://calendly.com/supersoft"
                                    /*
                                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                    */
                                    rootElement={document.getElementById("root")}
                                    text="Set a demo call"
                                    textColor="#ffffff"
                                    color="#00a2ff"
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

export default Appointment;