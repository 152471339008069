import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {AiOutlineMail} from 'react-icons/ai'
import {MdOutlineLocationOn} from 'react-icons/md'
import {BsTelephoneFill} from 'react-icons/bs'


class ContactUs extends Component {
  render() {
    return (
      <React.Fragment>
        {/* CTA START  */}
        <section id="contactus" className="section position-relative bg-gradient">
          <div className="bg-cta-overlay" />
          <Container className="container">
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center">
                  <h2 className="text-white mb-3">
                   Contact Us
                  </h2>
                </div>
                <Row className="display-flex justify-content-space-between text-white">
                  <Col lg={4} sm={12}>
                  <div className="contact-box email">
                  <AiOutlineMail  className="icons"/>
                    <h6>Contact@supersoft.com.pk</h6>
                  </div>
                  </Col>
                  <Col lg={4} sm={12}>
                 <div className="contact-box" >
                 <MdOutlineLocationOn className="icons"/>
                   <h6>3rd Floor, HBL Academic Center, IBA City Campus, Saddar, Karachi</h6>
                 </div>
                  </Col>
                  <Col lg={4} sm={12}>
                  <div className="phone-box">
                  <BsTelephoneFill className="icons" />
                    <h6>+1 (604) 243-2388</h6>
                  </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* CTA END  */}
      </React.Fragment>
    );
  }
}

export default ContactUs;
